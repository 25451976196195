/* You can add global styles to this file, and also import other style files */

// ag-grid customization
// $ag-primary-color: rgb(0, 114, 206);
// $ag-accent-color: rgb(0, 191, 111);
// $ag-selected-color: rgb(136, 201, 255, 0.8);
// $ag-hover-color: rgb(230, 232, 234);
// $ag-secondary-font-weight: 600;
// $ag-secondary-font-size: 16px;
// $ag-icon-size: 14px;
// $ag-font-family: 'Gilroy';
//Prime NG Customization
$menuitemHoverBg: #349c6c;
$menuitemActiveBg: #349c6c;
@import "@ag-grid-community/core/dist/styles/ag-grid.css";
@import "@ag-grid-community/core/dist/styles/ag-theme-balham.css";
@font-face {
    font-family: "gilroy";
    src: url(assets/fonts/Gilroy-Medium.ttf) format("opentype");
}

body {
    .p-component {
        padding: 2em;
        padding: 0px;
    }
}

// Grid custom Prime Components
.multiselection-dropDown-div {
    height: 300px;
    position: fixed;
    width: 271px;
    margin-bottom: 3px;
}

.grid-multiselection {
    height: 30px !important;
    width: 100% !important;
    align-items: center !important;
}

.numeric-div {
    height: 300px;
    position: fixed;
    width: 50px;
    margin-bottom: 3px;
}

.grid-numeric {
    height: 30px !important;
    width: 80% !important;
    align-items: center !important;
}

.inline-block {
    display: inline-block;
}

// END Grid custom Prime Components
//PRIME Library
.p-splitter-horizontal {
    >.p-splitter-gutter {
        cursor: col-resize;
        width: 10px !important;
    }
}

.p-splitter-panel-nested {
    display: inline-block !important;
}

.p-panelmenu-header-link {
    height: 25px;
}

.dropdown-div {
    height: 150px;
    position: fixed;
    width: 150px;
    margin: auto;
}

.grid-dropdown {
    height: 35px !important;
    width: 100% !important;
    align-items: center !important;
}

.p-menuitem-link:focus {
    box-shadow: inset 0 0 0 0.15rem #77b3978a !important;
    color: white !important;
    background-color: #9accb298 !important;
}

.p-menuitem-link:not(.p-disabled):hover {
    background: #9accb298 !important;
}

.ag-theme-balham .ag-row-selected::before {
    background-color: #ecf0f1;
}

.ag-theme-balham .ag-row-hover.ag-row-selected::before {
    background-color: #ecf0f1;
    background-color: var(--ag-row-hover-color, #ecf0f1);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#cacfd0), to(#cacfd0));
    background-image: linear-gradient(#cacfd0, #cacfd0);
}

.ag-theme-balham .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
.ag-theme-balham .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-balham .ag-ltr .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
.ag-theme-balham .ag-ltr .ag-cell-range-single-cell,
.ag-theme-balham .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
.ag-theme-balham .ag-rtl .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
.ag-theme-balham .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-balham .ag-rtl .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
.ag-theme-balham .ag-rtl .ag-cell-range-single-cell,
.ag-theme-balham .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
    border: 1px solid;
    border-color: #5E81AC;
    border-color: var(--ag-range-selection-border-color, var(--ag-balham-active-color, #5E81AC));
    outline: initial;
}

.ag-theme-balham {
    font-size: 13px;
}

.ag-header-group-text {
    font-weight: bold;
}
.ag-header-cell-text {
    font-weight: bold;
}

.p-confirm-popup-reject{
    float: right !important;
    margin-left: 5px !important;
}
